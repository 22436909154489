import { useState, useMemo, Fragment } from "react";
import { Link, useParams } from "react-router-dom";

function EntityDetail({ entity }) {
  const { countryURL, entityName } = useParams();
  const { query, setQuery, filteredEntity } = useSearchInEntity(entity);

  /*
  const [query, setQuery] = useState('')
  const [filteredEntity, setFilteredEntity] = useState(entity)
  
  // const filteredEntity = entity.filter(item => item.name.toLowerCase().includes(query.toLowerCase()))
  
  useMemo(
    () => {
      const result = () => entity.filter(item => item.name.toLowerCase().includes(query.toLowerCase()))
      setFilteredEntity(result)
    }, [entity, query]
    
  )
  */

  function useSearchInEntity() {
    const [query, setQuery] = useState("");
    const [filteredEntity, setFilteredEntity] = useState(entity);

    // const filteredEntity = entity.filter(item => item.name.toLowerCase().includes(query.toLowerCase()))

    useMemo(() => {
      const result = () =>
        entity.filter((item) =>
          item.name.toLowerCase().includes(query.toLowerCase())
        );
      setFilteredEntity(result);
    }, [entity, query]);

    return { query, setQuery, filteredEntity };
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-center w-100">
        <div>
          <div className="input-group mt-4">
            <span className="input-group-text" id="keywords">
              Search
            </span>
            <input
              type="text"
              placeholder="filter"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => setQuery("")}
            >
              x
            </button>
          </div>
        </div>
      </div>
      {filteredEntity.map((entity) => {
        return (
          <div key={entity.id} className="col-6 d-flex justify-content-center">
            <Link
              to={`/country/${countryURL}/${entityName}/show/${entity.id}`}
              className="shadow w-100 p-4 my-4 rounded text-reset text-decoration-none"
            >
              <h1>{entity.name}</h1>
              <ul>
                <li>
                  <b>Top's Text: </b>
                  {entity.seo_text ? (
                    entity.seo_text.list_top
                  ) : (
                    <span className="text-muted">EMPTY FIELD</span>
                  )}
                </li>
                <li>
                  <b>Bottom's Text: </b>
                  {entity.seo_text ? (
                    entity.seo_text.list_bottom
                  ) : (
                    <span className="text-muted">EMPTY FIELD</span>
                  )}
                </li>
                <li>
                  <b>Title Tag: </b>
                  {entity.seo_text ? (
                    entity.seo_text.title_tag
                  ) : (
                    <span className="text-muted">EMPTY FIELD</span>
                  )}
                </li>
                <li>
                  <b>Alt Tag: </b>
                  {entity.seo_text ? (
                    entity.seo_text.alt_tag
                  ) : (
                    <span className="text-muted">EMPTY FIELD</span>
                  )}
                </li>
                <li>
                  <b>Keywords: </b>
                  {entity.seo_text ? (
                    entity.seo_text.keywords
                  ) : (
                    <span className="text-muted">EMPTY FIELD</span>
                  )}
                </li>
              </ul>
            </Link>
          </div>
        );
      })}
    </Fragment>
  );
}

export default EntityDetail;
