import React from "react";
import Loading from "./Loading";
import EntityDetail from "./EntityDetail";
import { connect } from "react-redux";
import Http from "../services";

class EntityDetailContainer extends React.Component {
  state = {
    entities: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getEntities();
  }

  getEntities = async () => {
    this.setState({ isLoading: true });

    const headers = new Headers();
    headers.append("X-Requested-With", "XMLHttpRequest");
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${this.props.user.access_token}`);

    const entities = await Http.get(
      `https://${this.props.match.params.countryURL}/public/seo/${this.props.match.params.entityName}`
    );
    this.setState({ entities, isLoading: false });
  };

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <Loading isOpen={this.state.isLoading}></Loading>
          <EntityDetail entity={this.state.entities} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProp = ({ user }, props) => ({ user });

export default connect(mapStateToProp)(EntityDetailContainer);
