import './styles/form.css'

function EntityForm (props) {
  return (
    <form className="row justify-content-center my-4" onSubmit={props.onSubmit}>
      <div className="col-10 col-md-4">
        <div className="input-group mb-3">
          <span className="input-group-text tag-w" id="topText">Top's Text</span>
          <input
            type="text"
            onChange={props.onChange}
            className="form-control"
            placeholder="Top's Text"
            aria-label="listTop"
            name="listTop"
            aria-describedby="listTop"
            value={props.entity.listTop}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text tag-w" id="bottomText">Bottom's Text</span>
          <input
            type="text"
            onChange={props.onChange}
            className="form-control"
            placeholder="Bottom's Text"
            aria-label="listBottom"
            name="listBottom"
            aria-describedby="listBottom"
            value={props.entity.listBottom}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text tag-w" id="titleTag">Title Tag</span>
          <input
            type="text"
            onChange={props.onChange}
            className="form-control"
            placeholder="Title Tag"
            aria-label="titleTag"
            name="titleTag"
            aria-describedby="titleTag"
            value={props.entity.titleTag}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text tag-w" id="altTag">Alt Tag</span>
          <input
            type="text"
            onChange={props.onChange}
            className="form-control"
            placeholder="Bottom's Text"
            aria-label="altTag"
            name="altTag"
            aria-describedby="altTag"
            value={props.entity.altTag}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text tag-w" id="keywords">Keywords</span>
          <input
            type="text"
            onChange={props.onChange}
            className="form-control"
            placeholder="Keywords"
            aria-label="keywords"
            name="keywords"
            aria-describedby="keywords"
            value={props.entity.keywords}
          />
        </div>        		
      </div>
      <div className="w-100"></div>
      <div className="col-2">
        <button className="btn btn-primary">Save</button>
      </div>
    </form>
  )
}

export default EntityForm