import { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import EntitiesList from "./EntitiesList";
import EntityDetail from "./EntityDetailContainer";
import ShowEntity from "./ShowEntity";
import CountriesList from "./CountriesList";
import LoginForm from "./LoginForm";
import Layout from "./layout";
import NotFound from "./NotFound";
import RedirecToHome from "./RedirecToHome";
import RedirecToLogin from "./RedirecToLogin";
import { connect } from "react-redux";

class App extends Component {
  IsSelectedCountry = (component) =>
    Object.keys(this.props.country).length ? component : RedirecToHome;
  isLogin = (component) =>
    this.props.user.access_token ? component : RedirecToLogin;

  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Switch>
            <Route exact path="/" component={this.isLogin(CountriesList)} />

            <Route
              exact
              path="/country/:countryURL"
              component={this.IsSelectedCountry(EntitiesList)}
              country={this.props.country}
            />
            <Route
              exact
              path="/country/:countryURL/:entityName"
              component={this.IsSelectedCountry(EntityDetail)}
            />
            <Route
              exact
              path="/country/:countryURL/:entityName/show/:entityId"
              component={this.IsSelectedCountry(ShowEntity)}
            />
            <Route
              exact
              path="/country/:countryURL/:entityName/edit/:entityId"
              component={this.IsSelectedCountry(EntityDetail)}
            />

            <Route exact path="/login" component={LoginForm} />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </BrowserRouter>
    );
  }
}

const mapStateToProp = ({ country, user }, props) => ({ country, user });

export default connect(mapStateToProp)(App);
