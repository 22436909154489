import React, { Component } from "react";

export default class Footer extends Component {
  state = {
    date: new Date().getFullYear()
  }

  render() {
    return (
      <footer className="App-header bg-dark text-white position-fixed w-100 bottom-0">
        <div className="container-fluid py-2 d-flex justify-content-center align-items-center">
          <h5 className="font-weight-bold mb-0">Yotalis @ {this.state.date}</h5>
        </div>
      </footer>
    );
  }
}
