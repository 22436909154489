import React from 'react'
import { connect } from 'react-redux'
import Loading from './Loading'
import EntityForm from './EntityForm'
import Http from '../services'

class ShowEntity extends React.Component
{
  state = {
    entity: {},
    isLoading: false,
    form: {
      listTop: '',
      listBottom: '',
      titleTag: '',
      altTag: '',
      keywords: ''
    }
  }


  componentDidMount () {
    this.getEntity()
  }

  getEntity = async () => {
    const { countryURL, entityName, entityId } = this.props.match.params
    this.setState({ isLoading: true })

    try {
      const entity = await Http.get(
        `https://${countryURL}/public/seo/${entityName + '/' + entityId}`)
      this.setState({ entity, form: entity.seoText || this.state.form, isLoading: false })
    } catch (error) {
      console.error(error)
      this.setState({ isLoading: false })
    }
  }

  handleChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ isLoading: true, error: null })

    const { countryURL, entityName } = this.props.match.params

    try {
      const headers = new Headers()
      headers.append("X-Requested-With", "XMLHttpRequest")
      headers.append("Content-Type", "application/json")
      headers.append("Authorization", `Bearer ${this.props.user.access_token}`)
      const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(this.state.form)
      }

      const seosTextsResponse  = await fetch(`https://${countryURL}/public/seo/${entityName}/${this.state.entity.id}`, options)
      const seosTexts = await seosTextsResponse.json()
      this.setState({ form: seosTexts, isLoading: false })
    } catch (error) {
      console.log(error)
      this.setState({ isLoading: false, error: error })
    }
  }

  render () {
    return (
      <React.Fragment>
        <Loading isOpen={this.state.isLoading}></Loading>
        
        <h2 className="text-capitalize">{this.props.match.params.entityName} -> {this.state.entity.name} </h2>

        <EntityForm
          entity={this.state.form}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        />
        
      </React.Fragment>
    )
  }
}

const mapStateToProp = ({user}, props) => ({ user })

export default connect(mapStateToProp)(ShowEntity)