import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import Http from "../services";

class CountriesList extends React.Component {
  state = {
    countries: [],
    selectedCountry: "",
    selectedEntity: "",
    isLoading: false,
  };

  async componentDidMount() {
    this.state.selectedCountry = this.state.selectedCountry || "yotalis.com";
    this.getEntity();
    this.props.dispatch({
      type: "SET_COUNTRY",
      payload: {},
    });
  }

  getEntity = async () => {
    this.setState({ isLoading: true });

    const countries = await Http.get(
      `https://yotalis.com/public/seo`,
      {},
      this.props.user.access_token
    );
    this.setState({ countries, isLoading: false });
  };

  render() {
    return (
      <div className="row">
        <Loading isOpen={this.state.isLoading}></Loading>
        {this.state.countries.map((country) => {
          return (
            <div className="col-6" key={country.acronym}>
              <Link
                to={`/country/${country.url}`}
                onClick={() => {
                  this.props.dispatch({
                    type: "SET_COUNTRY",
                    payload: country,
                  });
                }}
                className="text-reset text-decoration-none"
              >
                <div className="p-4 bg-white shadow rounded mx-4 my-2">
                  <div className="row">
                    <div className="col-2">
                      <img
                        className="rounded-circle img-fluid"
                        src={country.logo}
                        alt={country.acronym}
                      />
                    </div>
                    <div className="col-10">
                      <span>
                        {country.acronym} - {country.name}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  }
}
const mapStateToProp = ({ user }, props) => ({ user });

export default connect(mapStateToProp)(CountriesList);
