import { Fragment, Component } from 'react'
import Navbar from "./Navbar";
import Footer from "./Footer";
import { connect } from 'react-redux'

class Layout extends Component {
  render () {
    return (
      <>
        <Navbar country={this.props.country}/>
        {this.props.children}
        <Footer/>
      </>
    )
  }  
}

function mapStateToProps ({country}, props) {
 return { country }
}

export default connect(mapStateToProps)(Layout)