import { Link } from "react-router-dom";

export default function Navbar ({ country }) {
    return (
      <Link to="/" className="text-reset text-decoration-none">
        <header className="App-header bg-dark text-white">
          <div className="container-fluid py-3 d-flex justify-content-center align-items-center">
            <h3 className="font-weight-bold mb-0 text-capitalize">Massaggi SEO {country.name ? `(${country.name})` : ''}</h3>
          </div>
        </header>
      </Link>
    )
}
