function user(state = {}, {type, payload}) {
  switch (type) {
    case 'SET_API_KEY': {
      return payload
    }
    case 'SET_TOKEN': {
      return payload
    }
    default:
      return state
  }
}

export default user