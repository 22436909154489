import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

class EntitiesList extends React.Component
{
  state = {
    entities: [
      {name: 'categories'},
      {name: 'tags'},
      {name: 'type-massages'},
      {name: 'provinces'},
      {name: 'cities'}
    ]
  }

  render () {
    if (!this.state.entities.length) {
      return 'loading...'
    }

    return (      
      <div className="row">
        {
          this.state.entities.map(entity => {
            return (
              <div className="col-4" key={entity.name}>
                <Link to={`/country/${this.props.country.url + '/' + entity.name}`} className='text-reset text-decoration-none'>
                  <div className="p-4 bg-white shadow rounded">{entity.name}
                  </div>
                </Link>
              </div>
            )
          })
        }
      </div>
    )
  }
}

function mapStateToProp ({country}, props) {
  return { country }
}

export default connect(mapStateToProp)(EntitiesList)